module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PP8ZHWFT","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://smartmarketing.brand-sites.wp.s-cloud.fi/graphql","html":{"createStaticFiles":false,"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":60000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@sok-brand-sites/common-frontend-gatsby-theme/gatsby-browser.js'),
      options: {"plugins":[],"wpUrl":"https://smartmarketing.brand-sites.wp.s-cloud.fi","siteUrl":"https://smartmarketing.fi","gtm":{"id":"GTM-PP8ZHWFT","includeInDevelopment":false},"usercentricsId":"e4-i20hPp","postTypes":["Page","Post"],"pageTemplateExtension":".tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smart Marketing","short_name":"Smart Marketing","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6b699e292f88455f73198ca305ae0a93"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
